import {
    Col,
    Container,
    Row,
    Image
} from "react-bootstrap";
import StoreList from "../../components/stores/StoreList";
import { useLoaderData } from "react-router-dom";
import { gameImages } from "../../data/config";
import classes from './GameDetails.module.css';

const GameDetails = () => {
  const game = useLoaderData();

  return (
   //display the game details in a react bootstrap card
    <Container>
      <h2>{game.name}</h2>
      <Row>
        <Col md={3}>
          <Image className={classes.gameImage} src={`${gameImages}${game.image}`} alt={game.name} fluid/>
        </Col>
        <Col md={9}>
          <h3>Local Stores with this game in stock</h3>
          <StoreList stores={game.stores} />
        </Col>
      </Row>
      <Row className="mt-3"> {/* Adds margin-top */}
        <Col>
          <p dangerouslySetInnerHTML={{ __html: game.description }} className="p-3" /> {/* Adds padding */}
        </Col>
      </Row>
    </Container>
  );
};

export default GameDetails;
