import { useLoaderData, useParams } from "react-router-dom";
import StoreDetail from "../../components/stores/StoreDetail";
import EventList from "../../components/events/EventList";
import GameList from "../../components/games/GameList";
import { Tab, TabContainer, Tabs } from "react-bootstrap";

const EventsPage = () => {
    const store = useLoaderData();
    const tab = useParams().tab || 'events';

    return (
        <>
            <StoreDetail store={store} />
            <TabContainer>
                <Tabs defaultActiveKey={tab} >
                    <Tab eventKey="events" title="Events">
                        <section>
                            <EventList events={store.events} />
                        </section>
                    </Tab>
                    <Tab eventKey="inventory" title="Inventory">
                        <section>
                            <GameList games={store.inventory} />
                        </section>
                    </Tab>
                </Tabs>  
            </TabContainer>                      
        </>
    )
}

export default EventsPage;