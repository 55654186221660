import { QUERY_KEYS, queryClient } from '../data/config';
import { fetchGames } from '../data/game-api';
import { fetchStores } from '../data/store-api';
import { slugify } from '../components/shared/utilities/stringUtils';


const getGames = async (pageNum) => {
    // Need to add paging at some point
    const page = pageNum ?? 1;
    const data = await queryClient.fetchQuery(
        {
            //TODO: get games from database with search filter once we have more games
            queryKey: [QUERY_KEYS.GAMES, page],
            queryFn: ({ signal }) => fetchGames({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        });
    
    return data;
}

const getGamesWithStores = async (pageNum) => {
    // Need to add paging at some point
    const page = pageNum ?? 1;
    return await Promise.all([
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.STORES, page],
            queryFn: ({ signal }) => fetchStores({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        }),
        queryClient.fetchQuery(
        {
                queryKey: [QUERY_KEYS.GAMES, page],
                queryFn: ({ signal }) => fetchGames({ signal, page }),
                staleTime: 1000 * 60 * 60 * 24, // 1 Day
        })]
    );
}


export const gamesLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    const data = await getGames(page);
    
    return data;
}

export const availableGamesLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    const [stores, games] = await getGamesWithStores(page);
    //Get all games that any store has in inventory
    const inventoryIds = stores.map(store => store.inventoryids).flat();
    return games.filter(game => inventoryIds.includes(game.id));
}

export const gameLoader = async ({params}) => { //TODO: How will we do this when we want to pull id from the database?
    const page = 1;
    const [stores, games] = await getGamesWithStores(page);
    const game = games.find(game => slugify(game.name) === params.gameName);
    //Get all stores that have this game in inventory and attach them to the game
    const storesWithGame = stores.filter(store => store.inventoryids.includes(game.id));
    return {...game, stores: storesWithGame};
}