import { Outlet } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import configureAmplify from '../../AmplifyConfig'; // Import the Amplify configuration
import { environment } from '../../data/config'; // Import the config file

configureAmplify(); // Call the function to configure Amplify

const AdminLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = await session.tokens.idToken.payload['cognito:groups'] || [];
        const attributes = await fetchUserAttributes();
        if (groups.includes('store')) { // send owners to their store/convention etc.
          navigate(`/admin/store/${attributes.name}`); // Redirect to the store admin page
        }  // Send admins any where
        else if (!groups.includes('admin')) {
          navigate('/unauthorized'); // Redirect to an unauthorized page
        }
        // Eventually users to their dashboard
        
      } catch (error) {
        navigate('/'); // Redirect to home page on error
      }
    };
    if(environment === 'production') {
      checkAdminGroup();
    }
  }, [navigate]);
  return (
    <>
        <Outlet />
    </>
  );
}

const AuthenticatedAdminLayout =  environment === 'production' ? withAuthenticator(AdminLayout, { hideSignUp: true}) : AdminLayout;

export default AuthenticatedAdminLayout;
