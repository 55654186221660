import {Container, Row, Col} from 'react-bootstrap';
import classes from './About.module.css';
const AboutPage = () => {
    return (
        <Container>
            <Row className="my-5">
                <Col>
                    <h1 className={classes.display-4}>About <span className={classes.titleIAMGAMEText}>I AM GAME</span></h1>
                </Col>
            </Row>
            <Row className="mx-auto p-4">
                <Col className={classes.aboutText} md={12}>
                <p className={classes.lead}>In 2023, we embarked on a mission: to forge in-person connections among board game enthusiasts. Why? Because we believe the magic of board games lies in the laughter, the strategy, and the competition that only face-to-face interactions can offer.</p>
                    <p>At I AM GAME, we see board games as more than just games. They're a catalyst for community and friendship. Ever struggled to find the right game or the right group to play with? We're here to change that.</p>
                    <p>Local game stores are the heart of our community, yet they face immense challenges competing with online giants. I AM GAME aims to bridge that gap, offering a platform for stores to connect with customers, share events, and showcase their unique inventories.</p>
                    <p>Have you ever looked at your shelf, filled with games, and wished you could share them with someone new? We've noticed many gamers feel the same. I AM GAME is here to connect you with fellow enthusiasts eager to discover new games.</p>
                    <p>Buying, selling, or trading games; organizing events; it all seems like a workaround, doesn't it? We thought so too. That's why I AM GAME was created specifically for board gamers. Our goal? To help you find the perfect game, the perfect playgroup, and the perfect venue.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutPage;