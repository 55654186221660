import React from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import IAGCard from '../shared/IAGCard/IAGCard';
import styling from './List.module.css';

const MyGamesPage = () => {
    const gamesList = [];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState([

    ]);

    const [selected, setSelected] = useState(null);

    const handleChange = (event) => {
        if (event.target.value != null) {
            setSelected(event.target.value);
        }
    };

    const handleAdd = () => {
        if (selected != null && !data.includes(gamesList[selected])) {
            setData([gamesList[selected], ...data]);
        }
        setSelected(null);
        setShow(false);
    }

    return (
        <Container fluid="lg">
            <Row>
                <Col>
                    <Button onClick={handleShow}>Add Game</Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} className={styling.modalText}>
                <Modal.Header>
                    <Modal.Title>Add Game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Choose a game from our list to add
                    <br />
                    <Form.Select placeholder="Choose a game" onChange={handleChange} autoFocus={true}>
                        <option>Choose a game</option>
                        {gamesList.map((game, index) => {
                            return (
                                <option value={index}>{game.name}</option>)
                        })}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAdd}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row>
                {data.map((data) => {
                    return (
                        <IAGCard
                            title={data.name.substring(0, 24)}
                            text={data.description.substring(0, 250)}
                            dest={`/games/${data.id}`}
                            key={data.id}
                            Btext="Details"
                            url={data.src} /> )
                })}
            </Row>
        </Container>
    )
}

export default MyGamesPage;