import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import GameList from '../../components/games/GameList';
import NewGameModal from '../../components/admin/NewGameModal';

const AllGames = () => {
    //TODO: get games from database with search filter once we have more games
    const games = useLoaderData();
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <Container>
            <Row className="mb-3">
                <Col xs={3} className="d-flex justify-content-end">
                    {/* Button to open the modal */}
                    <Button className="btn-lg" variant="primary" onClick={handleOpenModal}>
                        Add New Game
                    </Button>
                </Col>
            </Row>

            <GameList games={games} />
            <NewGameModal show={showModal} handleClose={handleCloseModal} />
        </Container>
    );
}

export default AllGames;