import {Row, Button, Card, Col} from 'react-bootstrap';
import { dateTimeDisplayFormat } from "../../components/shared/utilities/dateTimeUtils";
import { LinkContainer } from 'react-router-bootstrap';
import classes from './EventModal.module.css';

const EventModal = ({ data }) => {
    return (
      <Card className="my-3">
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <h4>{data.location}</h4>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <div>{data.description}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.dateText}>
                {dateTimeDisplayFormat.format(new Date(data.start))} - {dateTimeDisplayFormat.format(new Date(data.end))}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

export const Footer = ({data}) => {
    return (
        <>
            <LinkContainer to={`/stores/${data.ownerid}`}>
                <Button>View Store</Button>
            </LinkContainer>
        </>
    );
}

export default EventModal;