import { useState } from "react";
import { Row, Col, Container, Form, FormText, FormControl, FormGroup, FormLabel, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function SignUpPage() {
  // const { signup, login } = useAuth();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [termsAcceptedError, setTermsAcceptedError] = useState("");
  
  const validateForm = () => {
    let isValid = true;

    // Reset error messages
    setEmailError("");
    setPasswordError("");
    setTermsAcceptedError("");

    // Email validation
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }

    // Password validation
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    }

    // Terms validation
    if (!termsAccepted) {
      setTermsAcceptedError("You must accept the terms of service to continue");
      isValid = false;
    }
    
    return isValid;
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // createUser({ TODO: Implement create user in auth provider, and database
      //   email: email,
      //   password: password
      // });
      navigate("/profile");
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <div className="signup">
            <Container>
              <Row>
                <div className="header">
                <header>Sign Up</header></div>
              </Row>
              <FormText>
                Already a member? <Link to={"/login"}>Log in</Link>
              </FormText>

              <Form>
                <FormGroup controlId="Email">
                  <FormControl
                    placeholder="Email"
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={validateForm}
                  />

                  <Col>
                    <span className="error">{emailError}</span>
                  </Col>
                </FormGroup>

                <FormGroup controlId="password">
                <FormLabel> </FormLabel>
                  <FormControl
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validateForm}
                  />

                  <Col>
                    <span className="error">{passwordError}</span>
                  </Col>
                </FormGroup>

                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  {/* TODO:Eventually this would be great as a modal */}
                  <label> I agree to the <a href="/terms-of-service" target="_blank">terms of services</a></label>

                  <Col>
                    <span className="error">{termsAcceptedError}</span>
                  </Col>

                <Button block size="lg" type="submit" onClick={handleSubmit}>
                  Sign Up
                </Button>

                <Button>Sign up with Google</Button>
                <Button>Sign up with Facebook</Button>
              </Form>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SignUpPage;
