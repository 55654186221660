import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import { gameImages } from '../../data/config';
import { slugify } from '../../components/shared/utilities/stringUtils';

const GameModal = ({ data }) => {
    return (
      <Card className="my-3 p-3">
        <Row noGutters>
          {/* Image Column */}
          <Col xs={12} md={6} className="text-center">
            <Image src={`${gameImages}${data.image}`} alt={`${data.name} Box Front`} fluid />
          </Col>
          {/* Description Column */}
          <Col xs={12} md={6} className="p-4 pt-md-0">
            <Card.Body>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
              {/* Conditionally render the "Find This Game Locally" button */}
              {data.showLocal && (
                //Button takes the user to the game details page using the game id using React routing
                <Button href={`/games/${slugify(data.name)}`}>Find This Game Locally</Button>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  }

export const Footer = ({data}) => {
    return (
        <>
            <Button href={`http://www.boardgamegeek.com/boardgame/${data.bggid}`} target="_blank">View on Board Game Geek</Button>
        </>
        );
}

export default GameModal;