import React from 'react';
import { Modal, Card } from 'react-bootstrap';
import {
    Authenticator,
  } from '@aws-amplify/ui-react';
import { createPortal } from 'react-dom';

export const AuthenticationModal = ({show, onClose, onLogin,}) => {

    return createPortal(
        <Modal show={show} onHide={onClose} onLogin={onLogin} centered>
            <Modal.Header closeButton>
                <Modal.Title>Log In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Card>
                <Card.Body>
                    <Authenticator hideSignUp >
                    {({ signOut, user }) => {
                        // Call handleLogin with the user object when the component renders
                        onLogin();
                        return null; // Adjust based on what you want to render post-login
                    }}
                    </Authenticator>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>, document.getElementById("modal")
    );
};