import { Container, Row, Col, TabContainer, Tabs, Tab, Card, ListGroup, ListGroupItem, Image, Button } from 'react-bootstrap';
import { useLoaderData, Link } from 'react-router-dom';
import profileData from "../../data/profile.json";
import { Calendar, Eye, GeoAlt, PersonCircle, LockFill } from 'react-bootstrap-icons';
import styling from './GroupDetailPage.module.css';
import AddPlay from '../../components/groups/AddPlayComponent';
import JoinButton from '../../components/groups/JoinGroup';
import ShareButton from '../../components/shared/ui/buttons/ShareButton';
import MembersList from '../../components/groups/MembersList';
import PlaysList from '../../components/groups/PlaysList';
import playsData from '../../data/play.json';


const GroupDetailPage = () => { 
    const group = useLoaderData();

    const owner = profileData.filter(profile => profile.uuid === group.creatorID)[0];//find Created By: 
    const groupPlayList = playsData.filter(playList => playList.groupid === group.groupID);

    const detailsDisplayed = () => {
        //switch-case
        //if group private, dont show info ? 
        //if logged in user is admin or creator
        //display edit group button
        //else if user isnt logged in || user isnt in group 
        //display join group button
    };

    function groupOwnerDisplay() {
        //page if being viewed by owner/admin
        return(
            <>
                <Container>
                    <br/>
                    <Row className='justify-content-center'>
                        <Image className={styling.groupPic} src={group.groupPhoto} />
                    </Row>
                    <br/>
                    {/* group info  */}
                    <Row>
                        <Col md={4}>
                            <h3>{group.groupName}</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-space-around'>
                        <Col sm={9}>
                            <h5>{group.isPublic}</h5>
                            <h6>{group.memberCount} Members</h6>
                        </Col>
                        <Col sm={2}>
                            <Link to={'edit'}><Button>Edit Group</Button></Link>
                        </Col>
                        <Col sm={1}>
                            <ShareButton/>
                        </Col>
                    </Row>
                    <Row>
                        <Container>
                            <TabContainer justify>
                                <Tabs defaultActiveKey="about">
                                    <Tab eventKey="about" title="About" id="about">
                                        <Container>
                                            <br/>
                                            <Row className={styling.aboutTabCards}>
                                                <Col>
                                                    <Card className={styling.infoCard}>
                                                        <Card.Body>
                                                            <Card.Title>About</Card.Title>
                                                            <Card.Text>{group.about}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card className={styling.infoCard}>
                                                        <Card.Body className={styling.cardTitle}>
                                                            <Card.Title>Group Info:</Card.Title>
                                                        </Card.Body>
                                                        <ListGroup className={styling.infoCardList}>
                                                            <ListGroupItem className={styling.infoCardListItem}>
                                                                <Eye/> {group.isPublic}
                                                            </ListGroupItem>
                                                            <ListGroupItem className={styling.infoCardListItem}>
                                                                <Calendar/> Date Created: {group.dateCreated}
                                                            </ListGroupItem>
                                                            <ListGroupItem className={styling.infoCardListItem}>
                                                                <PersonCircle/> Created By: {(owner.firstname).concat( ' ', owner.lastname)}
                                                            </ListGroupItem>
                                                            <ListGroupItem className={styling.infoCardListItem}>
                                                                <GeoAlt/> Location: {group.location}
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="members" title="Members" id="about">
                                        <Container>
                                            <br/>
                                            <h3>Members</h3>
                                            <Container className={styling.groupList}>
                                                <MembersList input={group.memberList} />
                                            </Container>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey='games' title='Games' id='games'>
                                        <br/>
                                        <h3>Games</h3>
                                    </Tab>
                                    <Tab eventKey='plays' title='Plays' id='plays'>
                                        <Container>
                                            <br/>
                                            <Row className='justify-content-space-around'>
                                                <Col sm={10}>
                                                    <h3>Plays</h3>
                                                </Col>
                                                <Col sm={2}>
                                                    <AddPlay/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Container>
                                                    {/* <PlaysList input={group.playList} /> */}
                                                </Container>
                                            </Row>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </TabContainer>
                        </Container>
                    </Row>
                </Container>
            </>
        );
    }

    function privateGroupDisplay() {
        //page if group info not to be displayed
        return(
            <>
                <Container>
                    <br/>
                    <Row className='justify-content-center'>
                        <Image className={styling.groupPic} src={group.groupPhoto} />
                    </Row>
                    <br/>
                    {/* group info  */}
                    <Row>
                        <Col md={4}>
                            <h3>{group.groupName}</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-space-around'>
                        <Col sm={9}>
                            <h5>{group.isPublic}</h5>
                            <h6>{group.memberCount} Members</h6>
                        </Col>
                        <Col sm={2}>
                            <JoinButton/>
                        </Col>
                        <Col sm={1}>
                            <ShareButton/>
                        </Col>
                    </Row>
                    <Row>
                        <Container>
                            <Row>
                                <LockFill/>
                            </Row>
                            <Row>
                                <h3>This group is private.</h3>
                            </Row>
                            <Row>
                                <h4>Only users in the group may see this content.</h4>
                            </Row>
                        </Container>
                    </Row>
                </Container>
            </>
        );
    }

    function standardDisplay() {

    }

    return(
        <>
            <Container>
                <br/>
                <Row className='justify-content-center'>
                    <Image className={styling.groupPic} src={group.groupPhoto} />
                </Row>
                <br/>
                {/* group info  */}
                <Row>
                    <Col md={4}>
                        <h3>{group.groupName}</h3>
                    </Col>
                </Row>
                <Row className='justify-content-space-around'>
                    <Col sm={9}>
                        <h5>{group.isPublic}</h5>
                        <h6>{group.memberCount} Members</h6>
                    </Col>
                    {/* add if statement: if user not logged in | user not in group -> display button */}
                    <Col sm={2}>
                        <JoinButton/>
                    </Col>
                    <Col sm={1}>
                        <ShareButton/>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <TabContainer justify>
                            <Tabs defaultActiveKey="about">
                                <Tab eventKey="about" title="About" id="about">
                                    <Container>
                                        <br/>
                                        <Row className={styling.aboutTabCards}>
                                            <Col>
                                                <Card className={styling.infoCard}>
                                                    <Card.Body>
                                                        <Card.Title>About</Card.Title>
                                                        <Card.Text>{group.about}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card className={styling.infoCard}>
                                                    <Card.Body className={styling.cardTitle}>
                                                        <Card.Title>Group Info:</Card.Title>
                                                    </Card.Body>
                                                    <ListGroup className={styling.infoCardList}>
                                                        <ListGroupItem className={styling.infoCardListItem}>
                                                            <Eye/> {group.isPublic}
                                                        </ListGroupItem>
                                                        <ListGroupItem className={styling.infoCardListItem}>
                                                            <Calendar/> Date Created: {group.dateCreated}
                                                        </ListGroupItem>
                                                        <ListGroupItem className={styling.infoCardListItem}>
                                                            <PersonCircle/> Created By: {(owner.firstname).concat( ' ', owner.lastname)}
                                                        </ListGroupItem>
                                                        <ListGroupItem className={styling.infoCardListItem}>
                                                            <GeoAlt/> Location: {group.location}
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab eventKey="members" title="Members" id="about">
                                    <Container>
                                        <br/>
                                        <h3>Members</h3>
                                        <Container className={styling.groupList}>
                                            <MembersList input={group.memberList} />
                                        </Container>
                                    </Container>
                                </Tab>
                                <Tab eventKey='games' title='Games' id='games'>
                                    <br/>
                                    <h3>Games</h3>
                                </Tab>
                                <Tab eventKey='plays' title='Plays' id='plays'>
                                    <Container>
                                        <br/>
                                        <Row className='justify-content-space-around'>
                                            <Col sm={10}>
                                                <h3>Plays</h3>
                                            </Col>
                                            <Col sm={2}>
                                                <AddPlay/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Container>
                                                {/*<PlaysList input={groupPlayList} />*/}
                                            </Container>
                                        </Row>
                                    </Container>
                                </Tab>
                            </Tabs>
                        </TabContainer>
                    </Container>
                </Row>
            </Container>
        </>
    );
 }

 export default GroupDetailPage;