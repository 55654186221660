import { apiurl, environment } from "./config";
import mockStores from "./mockData/stores.json";

export const fetchStores = async ({signal, page}) => {
  if (environment === 'development') {
      return mockStores;
  }
  const response = await fetch(
      `${apiurl}/store`
  , { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the stores');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const stores = await response.json();

  return stores;
};

export const updateStore = async (store) => {
  if (environment === 'development') {
    return "Put Store Success";
  }
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(store)
  };
  const response = await fetch(`${apiurl}/store/${store.id}`, requestOptions);

  if (!response.ok) {
    const error = new Error('An error occurred while updating the store');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const data = await response.json();
  return data;
}