/*A bootstrap react header component*/
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../../../img/InfinityLogo.png";
import classes from "./Navigation.module.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { AuthenticationModal } from "../AuthenticationModal";


const Navigation = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Safely check if a user is currently logged in
    try {
      checkUserAuthentication();
    } catch (error) {
      console.error('Error checking current user:', error);
    }

    const handleAuthEvents = ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          setIsLoggedIn(true);
          break;
        case 'signedOut':
          setIsLoggedIn(false);
          break;
        default:
          console.log('Unknown auth event:', payload.event);
          break;
      }
    };

    const cancelListener = Hub.listen('auth', handleAuthEvents);

    // Cleanup listener when component unmounts
    return () => {
      cancelListener();
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount 

  const checkUserAuthentication = async () => {
    try {
      await getCurrentUser();
      setIsLoggedIn(true); // User is logged in
    } catch (error) {
      setIsLoggedIn(false); // User is not logged in
    }
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };


  const onLogin = () => {
    setShowLoginModal(false);
  }

  const handleLogoutClick = async () => {
    try {
      await signOut();
      setIsLoggedIn(false); // User is not logged in
      navigate('/');
      // Update state or redirect user after successful logout
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };


  return (
    <>
      <Navbar expand="md" variant="dark" className={classes.globalNav}>
        <img
          className={classes.logo}
          src={logo}
          alt="Infinity Symbol with Pips inside"
        />
        <LinkContainer to="/">
          <Navbar.Brand>I AM GAME<sup>TM</sup></Navbar.Brand>
        </LinkContainer>
        <div className={classes.version}>ALPHA</div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className={classes.globalNavMenu}>
            {/* <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/events">
              <Nav.Link>Events</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/games">
              <Nav.Link>Games</Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/groups">
              <Nav.Link>Groups</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/stores">
              <Nav.Link>Stores</Nav.Link>
            </LinkContainer>
            {/* {<NavDropdown
              title={isLoggedIn ? profile.username  : "Login"}
              id="basic-nav-dropdown"
              data-bs-theme="dark"
            > */}
            {isLoggedIn ? (
            // Show logout link or button
            <button className="nav-link" onClick={handleLogoutClick}>Logout</button>
            ) : (
            // Show login link
            <button className="nav-link" onClick={toggleLoginModal}>Log In</button>          )}
          {/*isLoggedIn && (
              <>
                <LinkContainer to={`/profile`}>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={handleLogoutClick}>Logout</NavDropdown.Item>
              </>
            )}
          </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AuthenticationModal show={showLoginModal} onClose={toggleLoginModal} onLogin={onLogin} />
    </>
  );
};

export default Navigation;
