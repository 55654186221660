import { Row, Col, Image, Card, Button } from 'react-bootstrap';
import { FaPhone, FaGlobe, FaFacebook } from 'react-icons/fa';
import { storeImages } from '../../data/config';
import { Link } from 'react-router-dom';

const StoreModal = ({ data }) => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
    return (
    <Card>
      <Card.Body>
        <Row>
          {/* Image Column */}
          <Col sm={12} order={1} lg={6} order-lg={2} className="mb-3">
            <Image src={`${storeImages}${data.image}`} alt={`${data.storename} Storefront`} fluid />
          </Col>

          {/* Details and Opening Times Column */}
          <Col sm={12} order={2} lg={6} order-lg={1}>
            <Card.Title>{data.storename}</Card.Title>
            <Card.Text>
              <FaPhone /> {data.phonenumber}
            </Card.Text>
            <Card.Text>
              {data.address[0].street1}
              {data.address[0].street2 && ` ${data.address[0].street2}`}<br />
              {data.address[0].city}, {data.address[0].state} {data.address[0].zip}
            </Card.Text>
            <Card.Link href={data.website}><FaGlobe /> Website</Card.Link>
            <Card.Link href={data.facebook}><FaFacebook /> Facebook</Card.Link>

            {/* Opening Times */}
            {daysOfWeek.map(day => (
              <Card.Text key={day}>
                <strong>{day}:</strong> {typeof data.hours[day] === 'string' ? 
                data.hours[day] : 
                `${data.hours[day].Open} - ${data.hours[day].Close}`}
              </Card.Text>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const Footer = ({data}) => {
    return (
        <>
            <Link to={`${data.storename.replace(/\s+/g, '-')}/events`}><Button>Upcoming Events</Button></Link>
            <Link to={`${data.storename.replace(/\s+/g, '-')}/inventory`}><Button>See Inventory</Button></Link>
        </>
        );
}

export default StoreModal;