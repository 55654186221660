import { QUERY_KEYS, queryClient } from "../../data/config";
import { addEvent, removeEvent, updateEvent } from "../../data/event-api";
import { formatDateForDB, dateTimeDisplayFormat } from "../../components/shared/utilities/dateTimeUtils";
import NewEventModal from "../../components/events/NewEventModal";
import { useState } from "react";
import { Table, Button } from 'react-bootstrap';
import RemoveButton from "../shared/ui/buttons/RemoveButton";
import { LocationType } from "../../data/objects/Enums";
import classes from './Managers.module.css';

const EventManager = ({store}) => {
    const [showEventModal, setShowEventModal] = useState(false);
    const [addingEvent, setAddingEvent] = useState(false);
    const [eventError, setEventError] = useState('');
    const [events, setEvents] = useState(store.events);
    const [editEvent, setEditEvent] = useState(null);
    const toggleNewEventModal = () => {
        if(showEventModal) {
            setEditEvent(null);
        }
        setShowEventModal(!showEventModal);
    }

    const onRemoveEventClick = async (eventId) => {
        const result = await removeEvent(eventId);
        if(result.includes('Error')) {
            setEventError(result);
        } else {
            setEventError('');
            setEvents(events.filter(event => { return event.id !== eventId }));
            queryClient.refetchQueries([QUERY_KEYS.EVENTS]);
        }
    }

    const onEditEventClick = async (eventId) => {
        const event = events.find(event => { return event.id === eventId });
        setEditEvent(event);
        toggleNewEventModal();
    }

    const appendEvents = async (event, repeat, ids) => {
        let newEvents = [];
        for(let i = 0; i < repeat; i++) {
            event.id = ids[i];
            newEvents.push(event);
            event.start = formatDateForDB(new Date((new Date(event.start)).getTime() + 7 * 24 * 60 * 60 * 1000));
            event.end = formatDateForDB(new Date((new Date(event.end)).getTime() + 7 * 24 * 60 * 60 * 1000));
        }
        setEvents(events.concat(newEvents));
    }

    const editEvents = (editedEvent) => {
        const updatedEvents = events.map(event => {
            if (event.id === editedEvent.id) {
                // Return the edited event details
                return { ...editedEvent };
            }
            return event;
        });
    
        // Update the events state
        setEvents(updatedEvents);
    }

    const onEventSave = async (event, repeat) => {
        toggleNewEventModal();
        setAddingEvent(true);
        //TODO: This will move to the modal eventually
        event.location = store.storename;
        event.locationtype = LocationType.Store.name;
        event.parenteventid = "";
        const result = event.id ? await updateEvent(event) : await addEvent(event, repeat);
        setAddingEvent(false);
        if(result.includes('Error')) {
            setEventError(result);
        } else {
            setEventError('');
            if(!event.id) {
                appendEvents(event, repeat, result);
            }
            else {
                editEvents(event);
            }
            queryClient.refetchQueries([QUERY_KEYS.EVENTS]);
        }
    }
    return (
        <>
            { addingEvent && 
                <section>
                    <h3>Adding Event....</h3>
                </section>
            }
            { eventError !== '' && 
                <section>
                    <h3>{eventError}</h3>
                </section>
            }
            <section>
                <Table className={classes.table} responsive>
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Description</th>
                            <th>Start</th>
                            <th>End</th>
                            <th></th>
                            <th><Button onClick={toggleNewEventModal}>Add</Button></th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        { events.sort((a, b) => new Date(a.start) - new Date(b.start)).map((event, index) => (
                            <tr key={index}>
                                <td >{event.name}</td>
                                <td>{event.description}</td>
                                <td>{dateTimeDisplayFormat.format(new Date(event.start))}</td>
                                <td>{dateTimeDisplayFormat.format(new Date(event.end))}</td>
                                <td><RemoveButton onClick={onEditEventClick} data={event.id} >Edit Event</RemoveButton></td>
                                <td><RemoveButton onClick={onRemoveEventClick} data={event.id} >Remove Event</RemoveButton></td>
                            </tr>
                        )) }
                        
                    </tbody>
                </Table>
            </section>
            <NewEventModal show={showEventModal} onClose={toggleNewEventModal} onSave={onEventSave} ownerId={store.id} event={editEvent} />  
        </>
    )
}

export default EventManager;