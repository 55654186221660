import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../data/config";
import { fetchGames } from "../../data/game-api";
import SearchBar from "../shared/ui/SearchBar";
import { useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap"
import { createPortal } from 'react-dom';
import classes from './AddGameModal.module.css';


const AddGameModal = ({show, onClose, onSave, ownedGames = []}) => {

    const [search, setSearch] = useState('');
    const [selectedGames, setSelectedGames] = useState([]);

    const page = 1; //TODO: at some point this will need to based on some search as thie list may get to long
    // OR a smaller query that only brings back names and ids
    const { isLoading, data: games, isError, error } = useQuery({
        queryKey: [QUERY_KEYS.GAMES, page],
        queryFn: ({ signal }) => fetchGames({ signal, page }),
        staleTime: 1000 * 60 * 60 * 24, // 1 Day
    })

    const onAddClick = () => {
        const games = selectedGames;
        setSelectedGames([]);
        onSave(games);
    }

    const onCloseClick = () => {
        setSelectedGames([]);
        onClose();
    }

    const handleGameClick = (e) => {
        const { id, checked } = e.target;
        setSelectedGames([...selectedGames, games.find(game => game.id === id)]);
        if (!checked) {
            setSelectedGames(selectedGames.filter(item => item.id !== id));
        }
    };
    
    const searchChangeHandler = (event) => { 
        setSearch(event.target.value);
    }

    const unownedGames = games.filter((game) => ownedGames.indexOf(game.id) === -1);
    
    const filteredGames = unownedGames.filter((game) => {
        if (search === '' || game.name.toLowerCase().includes(search.toLowerCase())
        || game.description.toLowerCase().includes(search.toLowerCase()))
            return game;
        return '';
    })

    return createPortal(
        <Modal
            show={show}
            onHide={onCloseClick}
            aria-labelledby="modal-add-games"
            aria-describedby="modal-add-games-info"
        >
            <Modal.Header closeButton>
            <Modal.Title>Add Games</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Card>
                <Card.Body>
                    {isLoading && <div>Loading...</div>}
                    {isError && <div>Error fetching games</div>}
                    <SearchBar onChange={searchChangeHandler}/>
                    <div className={classes.checkboxGrid}> {/* Use the grid layout for checkboxes */}
                        {filteredGames.map((game) => (
                            <div key={game.id} className="mb-3">
                                <Form.Check // Changed from Form.Check to Form.Switch
                                    type="checkbox"
                                    id={game.id} // Adjusted ID for clarity
                                    label={game.name}
                                    onClick={handleGameClick}
                                />
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Card>
            </Modal.Body>
            <Modal.Footer className={classes.stickyFooter}>
                <Button onClick={onAddClick}>Add Games</Button>
            </Modal.Footer>
        </Modal>, document.getElementById("modal")
    );
}


export default AddGameModal;