import { useLoaderData } from 'react-router-dom';
import EventList from '../../components/events/EventList';

const AllEvents = () => {
    //TODO: get games from database with search filter once we have more games
    const events = useLoaderData();

    return (
        <EventList events={events} />
    )
}

export default AllEvents;