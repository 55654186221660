import { QUERY_KEYS, queryClient } from '../data/config';
import { fetchStores } from '../data/store-api';
import { fetchGames } from '../data/game-api';
import { fetchEvents } from '../data/event-api';

export const storesLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    // const data = stores;
    const data = await queryClient.fetchQuery(
    {
        queryKey: [QUERY_KEYS.STORES, page],
        queryFn: ({ signal }) => fetchStores({ signal, page }),
        staleTime: 1000 * 60 * 60 * 24, // 1 Day
    });
    
    return data;
}

const getAllData = async (page) => {
    return await Promise.all([
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.STORES, page],
            queryFn: ({ signal }) => fetchStores({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        }),
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.EVENTS, page],
            queryFn: ({ signal }) => fetchEvents({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        }),
        queryClient.fetchQuery(
        {
                queryKey: [QUERY_KEYS.GAMES, page],
                queryFn: ({ signal }) => fetchGames({ signal, page }),
                staleTime: 1000 * 60 * 60 * 24, // 1 Day
        })]
    );

}

export const storeLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    
    const [stores, events, games] = await getAllData(page);

    const store = stores.find(store => store.storename === params.storeName.replace(/-/g, ' '));
      
    return {...store, events:events.filter(event => event.ownerid === store.id), inventory:games.filter(game => store.inventoryids.includes(game.id))};
}

export const storeLoaderAdmin = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    
    const [stores, events, games] = await getAllData(page);

    const store = stores.find(store => store.id === params.storeId);
      
    return {...store, events:events.filter(event => event.ownerid === store.id), inventory:games.filter(game => store.inventoryids.includes(game.id))};
}

export const inventoryLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    
    const [stores, games] = await Promise.all([
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.STORES, page],
            queryFn: ({ signal }) => fetchStores({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        }),
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.GAMES, page],
            queryFn: ({ signal }) => fetchGames({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        })]
    );

    const store = stores.find(store => store.id === params.storeId);
      
    return {...store, inventory:games.filter(game => store.inventoryids.includes(game.id))};
}

export const eventsLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    
    const [stores, events] = await Promise.all([
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.STORES, page],
            queryFn: ({ signal }) => fetchStores({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        }),
        queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.EVENTS, page],
            queryFn: ({ signal }) => fetchEvents({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        })]
    );

    const store = stores.find(store => store.id === params.storeId);
      
    return {...store, events:events.filter(event => event.ownerid === store.id)};
}