import { Container, Row } from 'react-bootstrap';
import styling from './TermsPage.module.css';

const TermsPage = () => {
    return (
        <Container>
            <h1>Terms of Service <span className="titleIAMGAMEText">I AM GAME</span></h1>
            <Row className="mx-auto p-4">
                <article>
                    <p>I AM GAME Terms of Service</p>
                    <p>Effective Date: December 15, 2023</p>
                    <ol className={styling.lineItems}>
                        <li clas>Acceptance of Terms:
                            By accessing or using I AM GAME ("the Service"), you agree to comply with and be bound by these Terms of Service.
                        </li>
                        <li>
                            Alpha Phase:
                            During the alpha phase, the Service is provided on an "as-is" basis. We make no guarantees about the availability, reliability, or functionality of the Service. Users are encouraged to report any issues or bugs encountered during this phase.
                        </li>
                        <li>
                            Eligibility:
                            You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you are at least 18 years old.
                        </li>
                        <li>
                            User Accounts:
                            <ol>
                                <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                                <li>You agree to provide accurate, current, and complete information during the registration process.</li>
                            </ol>
                        </li>
                        <li>
                            User Conduct:
                            <ol>
                                <li> You agree not to use the Service for any unlawful purpose or engage in any activity that violates these Terms of Service.</li>
                                <li>I AM GAME reserves the right to suspend or terminate accounts that engage in abusive or inappropriate behavior.</li>
                            </ol>                                
                        </li>
                        <li>
                            Intellectual Property:
                            <ol>
                                <li>I AM GAME retains all rights, title, and interest in and to the Service, including all related intellectual property rights.</li>
                                <li>You may not use, reproduce, distribute, or create derivative works based on the Service without explicit written permission.</li>
                            </ol>
                        </li>
                        <li>
                            Limitation of Liability:
                            <ol>
                                <li>I AM GAME shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages.</li>
                                <li>I AM GAME is not responsible for any loss or damage resulting from the use of the Service.</li>
                            </ol>
                        </li>
                        <li>
                            Changes to the Terms:
                            I AM GAME reserves the right to modify or revise these Terms of Service at any time. Users will be notified of significant changes.
                        </li>
                        <li>
                            Governing Law:
                            These Terms of Service are governed by and construed in accordance with the laws of Texas, USA.
                        </li>
                        <li>
                            Contact Information:
                            If you have any questions about these Terms of Service, please contact us at <a href='mailto=info@iamgame.community'>info@iamgame.community</a>.
                        </li> 
                    </ol>
                </article>
            </Row>
        </Container>
    )
}

export default TermsPage;