import { apiurl, environment } from "./config";
import mockEvents from "./mockData/events";
import { fetchAuthSession } from 'aws-amplify/auth';

export const fetchEvents = async ({ signal, page }) => {
  if (environment === "development") {
    return mockEvents;
  }
  const response = await fetch(`${apiurl}/event`, { signal: signal });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the events");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const games = await response.json();

  return games;
};

export const addEvent = async (event, repeat) => {
  if (environment === "development") {
    return "Post Event Success";
  }
  const session = await fetchAuthSession();
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(event),
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': session.tokens.idToken,
    },
  };
  let response = "";
  if (repeat && repeat > 1) {
    response = await fetch(`${apiurl}/event/${repeat}`, requestOptions);
  } else {
    response = await fetch(`${apiurl}/event`, requestOptions);
  }

  if (!response.ok) {
    const error = new Error("An error occurred while adding the event");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const data = await response.json();
  return data;
}

export const updateEvent = async (event, repeat) => {
  if (environment === 'development') {    
    return "Put Event Success";
  }
  const session = await fetchAuthSession();
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(event),
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': session.tokens.idToken,
    },
  };
  let response = await fetch(`${apiurl}/event/${event.id}`, requestOptions);
  const data = await response.json();
  return data;
}

export const removeEvent = async (eventId) => {
  if (environment === "development") {
    return "Delete Event Success";
  }
  const session = await fetchAuthSession();
  const requestOptions = {
    method: 'DELETE',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': session.tokens.idToken,
    },
  };

  const response = await fetch(`${apiurl}/event/${eventId}`, requestOptions);

  if (!response.ok) {
    const error = new Error("An error occurred while removing the event");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const data = await response.json();
  return data;
};
