import { Col, Image, Stack, Row } from "react-bootstrap";
import { storeImages } from "../../data/config";
import classes from "./StoreDetail.module.css";

const StoreDetail = ({ store }) => {
  const btnClass = `btn btn-outline-primary btn-sm ${classes.smallButton}`;

  return (
    <section className={classes.detailSection}>
      <Row>
        <h1 className="text-center mb-4"> {store.storename} </h1>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} md={4} lg={3} className="mb-3">
          <Image
            src={`${storeImages}${store.image}`}
            alt={`${store.storename} Storefront`}
            thumbnail
          />
        </Col>
        <Col xs={12} md={8} lg={5}>
          <Stack gap={3}>
            <div className="fs-5">
              <i className="bi bi-telephone-fill"></i> {store.phonenumber}
            </div>
            <div>
              <strong>Address:</strong>
              <br />
              {store.address[0].street1}
              <br />
              {store.address[0].street2 && (
                <>
                  {store.address[0].street2}
                  <br />
                </>
              )}
              {store.address[0].city}, {store.address[0].state}{" "}
              {store.address[0].zip}
            </div>
            <div>
              <a
                href={store.website}
                className={btnClass}
              >
                Website
              </a>
              <a
                href={store.facebook}
                className={btnClass}
              >
                Facebook
              </a>
            </div>
          </Stack>
        </Col>
        <Col xs={12} lg={4}>
          <h3>Hours of Operation</h3>
          <Row>
            <Col xs="6">
              <div>
                Monday:{" "}
                {typeof store.hours.Monday === "string"
                  ? store.hours.Monday
                  : `${store.hours.Monday.Open} - ${store.hours.Monday.Close}`}
              </div>
              <div>
                Tuesday:{" "}
                {typeof store.hours.Tuesday === "string"
                  ? store.hours.Tuesday
                  : `${store.hours.Tuesday.Open} - ${store.hours.Tuesday.Close}`}
              </div>
              <div>
                Wednesday:{" "}
                {typeof store.hours.Wednesday === "string"
                  ? store.hours.Wednesday
                  : `${store.hours.Wednesday.Open} - ${store.hours.Wednesday.Close}`}
              </div>
              <div>
                Thursday:{" "}
                {typeof store.hours.Thursday === "string"
                  ? store.hours.Thursday
                  : `${store.hours.Thursday.Open} - ${store.hours.Thursday.Close}`}
              </div>
            </Col>
            <Col xs="6">
              <div>
                Friday:{" "}
                {typeof store.hours.Friday === "string"
                  ? store.hours.Friday
                  : `${store.hours.Friday.Open} - ${store.hours.Friday.Close}`}
              </div>
              <div>
                Saturday:{" "}
                {typeof store.hours.Saturday === "string"
                  ? store.hours.Saturday
                  : `${store.hours.Saturday.Open} - ${store.hours.Saturday.Close}`}
              </div>
              <div>
                Sunday:{" "}
                {typeof store.hours.Sunday === "string"
                  ? store.hours.Sunday
                  : `${store.hours.Sunday.Open} - ${store.hours.Sunday.Close}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};
export default StoreDetail;
