import StoreLayout from "./StoreLayout";
import StoresPage from "./StoresPage";
import StorePage from "./StorePage";
import { storesLoader, storeLoader } from "../../loaders/storeLoaders";

const storeRoutes = {
  path: "stores",
  element: <StoreLayout />,
  children: [
    { index: true, element: <StoresPage />, loader: storesLoader },
    {
      path: ":storeName/:tab",
      element: <StorePage />,
      loader: storeLoader
    },
    {
      path: ":storeName",
      element: <StorePage />,
      loader: storeLoader
    },
    // {
    //   path: ":storeId/edit",
    //   element: <EditStorePage />,
    //   // loader: storeLoader
    // },
    // {
    //   path: ":storeId/events",
    //   element: <StorePage />,
    //   loader: storeLoader
    // },
    // {
    //   path: ":storeId/inventory",
    //   element: <StorePage />,
    //   loader: storeLoader
    // },
  ],
};

export default storeRoutes;
