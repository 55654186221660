import { useLoaderData } from 'react-router-dom';
import GameList from '../../components/games/GameList';

const AvailableGames = () => {
    const games = useLoaderData();
    //Add showLocal prop to every game and set to true
    games.forEach(game => game.showLocal = true);

    return (
        <GameList games={games} />
    )
}

export default AvailableGames;