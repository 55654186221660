import {Button} from 'react-bootstrap';

const RemoveButton = ({onClick, data, children}) => {
    const onRemoveClick = () => {
        onClick(data);
    }
    return (
        <Button onClick={onRemoveClick}>{children}</Button>
    )
}

export default RemoveButton;