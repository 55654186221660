import { useState } from "react";
import { Modal, Tabs, Tab, Form, Button, Card } from "react-bootstrap";
import { createPortal } from "react-dom";
import {
  newGame,
  newGameFromBGG,
  newGameFromBarcode,
} from "../../data/game-api";

const NewGameModal = ({ show, handleClose }) => {
  const [activeTab, setActiveTab] = useState("bggTab");
  const [bggIds, setBggIds] = useState("");
  const [barcode, setBarcode] = useState("");
  const [manualGame, setManualGame] = useState({
    name: "",
    description: "",
    image: "",
    bggid: "",
  });
  const [disableButton, setDisableButton] = useState(true);

  const checkButtonFormValidity = (tab, updatedManualGame) => {
    const bggIdPattern = /^\d+(\s*,\s*\d+)*$/;
    const isBggValid = bggIdPattern.test(bggIds);

    // Check if button should be enabled
    if (tab === "bggTab" && (!isBggValid || bggIds === "")) {
      setDisableButton(true);
    } else if (
      tab === "formTab" && (!updatedManualGame ||
      (updatedManualGame.name === "" ||
        updatedManualGame.description === "" ||
        updatedManualGame.image === ""))
    ) {
      setDisableButton(true);
    } else if (tab === "barcodeTab" && barcode === "") {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  //on tab change recheck button validity
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    checkButtonFormValidity(tab);
  };

  const handleCloseModal = (gameAdded) => {
    //clear all fields
    setBggIds("");
    setBarcode("");
    setManualGame({ name: "", description: "", image: "", bggid: "" });
    checkButtonFormValidity(activeTab, { name: "", description: "", image: "", bggid: "" });
    handleClose();
  };

  const handleButtonClick = () => {
    if (activeTab === "bggTab") {
      const bggIdArray = bggIds.split(",");
      //trim whitespace on each id
      bggIdArray.forEach((id, index) => {
        bggIdArray[index] = id.trim();
      });
      newGameFromBGG(bggIdArray);
    } else if (activeTab === "formTab") {
      const game = manualGame;
      newGame(game);
    } else if (activeTab === "barcodeTab") {
      const gameBarcode = barcode;
      newGameFromBarcode(gameBarcode);
    }
    handleCloseModal(true);
  };

  const onFieldChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "barcode") {
      setBarcode(value);
    } else if (name === "bgg") {
      setBggIds(value);
    } else {
      setManualGame((prevManualGame) => {
        const updatedManualGame = {
          ...prevManualGame,
          [name]: value,
        };
        checkButtonFormValidity(activeTab, updatedManualGame);
        return updatedManualGame;
      });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setManualGame((prevManualGame) => {
        const updatedManualGame = {
          ...prevManualGame,
          image: reader.result,
        };
        checkButtonFormValidity('formTab', updatedManualGame);
        return updatedManualGame;
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return createPortal(
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Game</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Tabs
              id="new-game-tabs"
              activeKey={activeTab}
              onSelect={(k) => handleTabChange(k)}
              className="mb-3"
            >
              <Tab eventKey="bggTab" title="BGG">
                <h3>Add from BoardgameGeek.com</h3>
                <p>
                  Please enter a comma separated list of the games you would
                  like to import from BoardgameGeek.com
                </p>
                <Form.Group controlId="formBGG">
                  <Form.Label>BGG ID(s)</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Enter BoardgameGeek Ids"
                    name="bgg"
                    onChange={onFieldChange}
                    value={bggIds}
                  />
                </Form.Group>
              </Tab>
              <Tab
                eventKey="formTab"
                title="Manual"
              >
                <h2>Add Game Manually</h2>
                <Form>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter game name"
                      name="name"
                      onChange={onFieldChange}
                      value={manualGame.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      placeholder="Enter game description"
                      name="description"
                      onChange={onFieldChange}
                      value={manualGame.description}
                    />
                  </Form.Group>
                  <Form.Group controlId="formImage">
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" required placeholder="Upload image" name="image" onChange={handleImageUpload} />
                  </Form.Group>
                </Form>
              </Tab>
              <Tab eventKey="barcodeTab" title="Barcode">
                <h2>Use Barcode Scanner</h2>
                <p>Please select the field and then scan the barcode</p>
                <Form.Group controlId="formBGG">
                  <Form.Label>Barcode</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Click here then scan"
                    name="barcode"
                    onChange={onFieldChange}
                    value={barcode}
                  />
                </Form.Group>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleButtonClick}
          disabled={disableButton}
        >
          {activeTab === "bggTab"
            ? "Add Games"
            : activeTab === "formTab"
            ? "Add Game"
            : "Submit Barcode"}
        </Button>
      </Modal.Footer>
    </Modal>,
    document.getElementById("modal")
  );
};

export default NewGameModal;
