import { useState, useEffect } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap"
import { createPortal } from 'react-dom';
import Event from '../../data/objects/Event';

const NewEventModal = ({show, onClose, onSave, ownerId, event}) => {
    const [newEvent, setNewEvent] = useState(new Event({ownerid: ownerId}));
    const [repeat, setRepeat] = useState("1");
    const [disableButton, setDisableButton] = useState(true);
    useEffect(() => {
        setNewEvent(new Event(
        {
            ownerid: event ? event.ownerid : ownerId,
            name: event ? event.name : '',
            description: event ? event.description : '',
            start: event ? event.start : '',
            end: event ? event.end : '',
            id: event ? event.id : ''
        }));
    },[ownerId, event])
    useEffect(() => {
        setDisableButton(
            newEvent.start === "" ||
            newEvent.end === "" ||
            newEvent.description === "" ||
            newEvent.name === "" ||
            (repeat === "" && !event)
        );
    }, [newEvent, repeat, event]);

    const onFieldChange = event => { 
        const { name, value } = event.target;
        setNewEvent({...newEvent, [name]: value});
    }

    const onRepeatChange = event => {
        setRepeat(event.target.value);
    }

    const onAddClick = () => {
        const event = newEvent;
        setNewEvent(new Event({ownerid: ownerId}));
        onSave(event, repeat);
    }

    const onCloseClick = () => {
        setNewEvent(new Event({ownerid: ownerId}));
        onClose();
    }

    return createPortal(
        <Modal
            show={show}
            onHide={onCloseClick}
            aria-labelledby="modal-add-event"
            aria-describedby="modal-add-event-info"
        >
            <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Card>
                <Card.Body>
                    <h2>Event Information</h2>
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" required placeholder="Enter Event Name" name="name" onChange={onFieldChange} value={newEvent.name} />
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" required placeholder="Enter Event Description" name="description" onChange={onFieldChange} value={newEvent.description} />
                        </Form.Group>
                        <Form.Group controlId="formStartTime">
                            <Form.Label>When does the event start?</Form.Label>
                            <Form.Control type="datetime-local" required name="start" onChange={onFieldChange} value={newEvent.start} />
                        </Form.Group>
                        <Form.Group controlId="formEndTime">
                            <Form.Label>When does the event end?</Form.Label>
                            <Form.Control type="datetime-local" required name="end" onChange={onFieldChange} value={newEvent.end}/>
                        </Form.Group>
                        {!event && (
                        <Form.Group controlId="formRepeat">
                            <Form.Label>How many weeks would you like to repeat this event?</Form.Label>
                            <Form.Control type="number" min="1" max="20" step="1" required name="repeat" onChange={onRepeatChange} value={repeat} />
                        </Form.Group>
                        )}
                    </Form>
                </Card.Body>
            </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disableButton} onClick={onAddClick}>{event ? "Edit Event" : "New Event"}</Button>
            </Modal.Footer>
        </Modal>, document.getElementById("modal")
    );
}


export default NewEventModal;