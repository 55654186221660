import GameLayout from './GameLayout'
import AvailableGames from './AvailableGames';
import GameDetails from './GameDetails'
import { availableGamesLoader, gameLoader } from "../../loaders/gameLoaders";

const gameRoutes = {
    path: 'games',
    element: <GameLayout />,
    children: [
        {index:true, element: <AvailableGames />, loader: availableGamesLoader },
        {
            path: ':gameName',
            element: <GameDetails />,
            loader: gameLoader
        },
    ]
  };

export default gameRoutes;
