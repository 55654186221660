import { useLoaderData } from 'react-router-dom';
import StoreList from '../../components/stores/StoreList';

const StoresPage = () => {  
    const stores = useLoaderData();

    return (
        <StoreList stores={stores}/>
    );
}

export default StoresPage;