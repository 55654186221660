import classes from './EditProfileComponent.module.css';
import { useState } from 'react';
import {
  Modal,
  Form,
  FormLabel,
  Button,
} from "react-bootstrap";

export default function EditProfileComponent() {
  const userProfile = {}; //TODO: get user profile from auth provider?

  /** 
  const [firstName, setFirst] = useState(test.firstName);
  const [lastName, setLast] = useState(test.lastname);
  const [username, setUsername] = useState(test.username);
  const [email, setEmail] = useState(test.email);
  const [password, setPassword] = useState(test.password);

  const firstNameChange = (event) => {
    setFirst(event.target.value);
  };
  const lastNameChange = (event) => {
    setLast(event.target.value);
  };
  const usernameChange = (event) => {
    setUsername(event.target.value);
  };
  const emailChange = (event) => {
    setEmail(event.target.value);
  };
  const passwordChange = (event) => {
    setPassword(event.target.value);
  };
  
  */
  
  const [show, setShow] = useState(false);
  
  const [email, setEmail] =  useState(userProfile.email);
  const [password, setPassword] =  useState(userProfile.password);
  const [username, setUsername] =  useState(userProfile.username);
  const [firstname, setFirstname] =  useState(userProfile.firstname);
  const [lastname, setLastname] =  useState(userProfile.lastname);
  const [phoneNumber, setPhoneNumber] =  useState(userProfile.phoneNumber);
  const [birthdate, setBirthday] =  useState(userProfile.birthdate);
  const [address, setAddress] =  useState(userProfile.address);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const onFirstEmailChange = (e) => {
    console.log(e);
    setEmail(e.target.value);
  }

  const onFirstPasswordChange = (e) => {
    console.log(e);
    setPassword(e.target.value);
  }

  const onFirstUsernameChange = (e) => {
    console.log(e);
    setUsername(e.target.value);
  }
  
  const onFirstNameChange = (e) => {
    console.log(e);
    setFirstname(e.target.value);
  }
  
  const onFirstLastNameChange = (e) => {
    console.log(e);
    setLastname(e.target.value);
  }

  const onFirstPhoneNumberChange = (e) => {
    console.log(e);
    setPhoneNumber(e.target.value);
  }
  const onFirstBirthdayChange = (e) => {
    console.log(e);
    setBirthday(e.target.value);
  }

  const onFirstAddressChange = (e) => {
    console.log(e);
    setAddress(e.target.value);
  }

  
  const saveChanges = (event) => {
    event.preventDefault();
    //TODO: save changes to database

    setShow(false);
  };

  return (
    <>
      <Button className={classes.editButton} onClick={handleShow}>
        Edit Profile
      </Button>

      <Modal
        className={classes.editModal}
        show={show}
        onHide={handleClose}
        aria-labelledby="modal-edit-profile"
        aria-describedby="modal-edit-profile-info"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form horizontal>
            <Form.Group controlId="edit-pic">
              <FormLabel column className={classes.formLabel}>
                Profile picture:
              </FormLabel>
              <Form.Control type="file" name="profilePic" />
            </Form.Group>

            <Form.Group controlId="edit-name">
              <FormLabel column className={classes.formLabel}>
                First name:
              </FormLabel>
              <Form.Control
                type="text"
                value={firstname}
                onChange={onFirstNameChange}
              />
            </Form.Group>
            <Form.Group controlId="edit-name">
              <FormLabel column className={classes.formLabel}>
                Last name:
              </FormLabel>
              <Form.Control
                type="text"
                value={lastname}
                onChange={onFirstLastNameChange}
              />
            </Form.Group>
            <Form.Group controlId="edit-username">
              <FormLabel column className={classes.formLabel}>
                Username:
              </FormLabel>
              <Form.Control
                type="text"
                value={username}
                onChange={onFirstUsernameChange}
              />
            </Form.Group>

            <Form.Group controlId="edit-email">
              <FormLabel column className={classes.formLabel}>
                Email:
              </FormLabel>
              <Form.Control
                type="email"
                value={email}
                onChange={onFirstEmailChange}
              />
            </Form.Group>

            <Form.Group controlId="edit-phoneNumber">
              <FormLabel column className={classes.formLabel}>
                Phone number:
              </FormLabel>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={onFirstPhoneNumberChange}
              />
            </Form.Group>

            <Form.Group controlId="edit-birthday">
              <FormLabel column className={classes.formLabel}>
                Birthday:
              </FormLabel>
              <Form.Control
                type="text"
                value={birthdate}
                onChange={onFirstBirthdayChange}
              />
            </Form.Group>

            <Form.Group controlId="edit-address">
              <FormLabel column className={classes.formLabel}>
                Address:
              </FormLabel>
              <Form.Control
                type="text"
                value={address}
                onChange={onFirstAddressChange}
              />
            </Form.Group>



            {/* Add more input fields for other profile information */}
          
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveChanges}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
