import { Container, Row } from 'react-bootstrap';
import IAGCard from '../shared/IAGCard/IAGCard';
import { storeImages } from '../../data/config';
import StoreModal, {Footer} from './StoreModal';
import SearchBar from '../shared/ui/SearchBar';
import { useState } from 'react';

const cardText = (address) => {
    return (
        <>
            {address.street1}<br/>
            {address.street2 !== '' &&
               <>{address.street2}<br/></>
            }
            {address.city}, {address.state}  {address.zip}<br/>
        </>
    );
}

const StoreList = ({stores}) => {  
    let showStores = stores;
    //TODO: Search Fiter for data. Until we have too many stores this can all be done locally
    const [search, setSearch] = useState('');
    const searchChangeHandler = (event) => {
        setSearch(event.target.value);
    }

    if(search !== '') {
        showStores = stores.filter((store) => {
            if (store.storename.toLowerCase().includes(search.toLowerCase()))
                return store;
            return '';
        })
    }

    return (
        <Container>
            <SearchBar onChange={searchChangeHandler} placeHolder="Store Name"/>
            <Row>
            {showStores.map((item) => (
                <IAGCard
                    title={item.storename}
                    text={cardText(item.address[0])}
                    modal={StoreModal}
                    modalFooter={Footer}
                    dest={item.id}
                    data={item}
                    key={item.id}
                    Btext="View"
                    image={`${storeImages}${item.image}`} 
                /> 
            ))}
            </Row>
        </Container>
    );
}

export default StoreList;