import { Container, ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styling from './Footer.module.css';

const Footer = () => {
  const footerStyleClass = `${styling.footer} text-center`;
  return (
    <footer className={footerStyleClass}>
      <Container>
        <Row>
            <ListGroup className={styling.listContent} variant="flush">
              <ListGroupItem className={styling.listTitle}><h4>Quick Links</h4></ListGroupItem>
              <ListGroupItem className={styling.listContent}><Link to="/about">About</Link></ListGroupItem>
              <ListGroupItem className={styling.listContent}><Link to="/contactus">Contact Us</Link></ListGroupItem>
              <ListGroupItem className={styling.listContent}><Link to="/terms-of-service">Terms of Service</Link></ListGroupItem>
            </ListGroup>
        </Row>
        <hr/>
        <Row>
          I AM GAME LLC
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
