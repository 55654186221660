import { Modal } from "react-bootstrap"
import { createPortal } from 'react-dom';

const IAGModal = ({ show, onClose, footer, title, children }) => {
    return createPortal(
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby="modal-edit-profile"
            aria-describedby="modal-edit-profile-info"
        >
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {footer && (
            <Modal.Footer>
                {footer}
            </Modal.Footer>
            )}
        </Modal>, document.getElementById("modal")
    );
}

export default IAGModal;