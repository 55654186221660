import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./pages/Routes";
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './data/config';
import '@aws-amplify/ui-react/styles.css';

function App() {
  return (
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router}>
        </RouterProvider>
      </QueryClientProvider> 
    );
}

export default App;
