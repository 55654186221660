import { apiurl } from "./config";
import mockGames from "./mockData/games.json";
import { environment } from "./config";
import { fetchAuthSession } from 'aws-amplify/auth';


export const fetchGames = async ({ signal, page }) => {
  if (environment === "development") {
    return mockGames;
  }
  const response = await fetch(`${apiurl}/game`, { signal: signal });

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the games");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const games = await response.json();

  return games;
};

export const newGame = async (game) => {
  if (environment === "development") {
    return "Post Game Success";
  }
  const session = await fetchAuthSession();
  const response = await fetch(`${apiurl}/game`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': session.tokens.idToken,
    },
    body: JSON.stringify(game),
  });

  if (!response.ok) {
    const error = new Error("An error occurred while adding the game");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const newGame = await response.json();

  return newGame;
};

export const newGameFromBGG = async (gameIds) => {
  if (environment === "development") {
    return "Post BBGames Success";
  }
  const response = await fetch(`${apiurl}/game/bgg`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(gameIds),
  });

  if (!response.ok) {
    const error = new Error("An error occurred while adding the game");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const newGame = await response.json();

  return newGame;
}

export const newGameFromBarcode = async (barcode) => {
  if (environment === "development") {
    return "Post Barcode Success";
  }
  const session = await fetchAuthSession();
  const response = await fetch(`${apiurl}/game/barcode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': session.tokens.idToken,
    },
    body: JSON.stringify(barcode),
  });

  if (!response.ok) {
    const error = new Error("An error occurred while adding the game");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const newGame = await response.json();

  return newGame;
}
