import { QUERY_KEYS, queryClient } from '../data/config';
import { fetchEvents } from '../data/event-api';

export const eventsLoader = async ({params}) => {
    // Need to add paging at some point
    const page = params.page ?? 1;
    const data = await queryClient.fetchQuery(
        {
            queryKey: [QUERY_KEYS.EVENTS, page],
            queryFn: ({ signal }) => fetchEvents({ signal, page }),
            staleTime: 1000 * 60 * 60 * 24, // 1 Day
        });

    const events = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    
    return events;
}

// export const eventLoader = async ({params}) => {
//     const eventId = params.eventId;
//     const data = await queryClient.fetchQuery(
//         {
//             queryKey: ['event', eventId],
//             queryFn: ({ signal }) => fetchEvent({signal, id: eventId }),
//             staleTime: 1000 * 60 * 60 * 24, // 1 Day
//         });

//     return data;
// }