import { Row, Col, Image, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import collectionImage from '../img/Collection.png';
import eventImage from '../img/events.jpg';
import groupImage from '../img/group.jpg';
import classes from './Home.module.css';

const HomePage = () => {
    return (
        <section className={classes.homePage}>
            <Container className={classes.homeContainer}>
                <Row className={classes.headerContent}>
                    <Col md="12">
                        <h1 className={classes.welcomeTitle}>Welcome To <span className={classes.titleText}>I AM GAME</span></h1>
                        <div>
                            <span className={classes.titleText}>I AM GAME</span> is a platform designed to connect local board gamers with local game stores and each other.
                        </div>
                    </Col>
                </Row>
                <Row className={classes.headerContent}>
                    <Col md="4" >
                        <ol className={classes.headerList}>
                            <li>Find a game store near you.</li>
                            {/* <li>Find other players near you.</li> */}
                        </ol>
                    </Col>
                    <Col md="4" >
                        <ol className={classes.headerList}>
                            <li>Find local Inventory</li>
                        </ol>
                    </Col> 
                </Row>
                {/* <Row className={classes.headerContent}>
                    <Col md="4" >
                        <Link to="/signup">
                            <Button size="lg" >Join Now</Button>
                        </Link>
                    </Col>
                </Row> */}
                <Row className={classes.headerContent}>
                    <Col md="12">
                        <ol className={classes.headerList}>
                            <li>Find events, from local game nights, to tournaments and conventions</li>
                            {/* <li>Get data driven suggetions based on ratings from actual plays</li> */}
                        </ol>
                    </Col>
                </Row>
                <Row className={classes.headerContent}>
                    <h1 className={classes.titleText}>Services</h1>
                </Row>
                <Row className={classes.headerContent}>
                    <Col md="4">
                        <Row className={classes.headerContent}>
                            <h2>Local Events</h2>
                        </Row>
                        <Row className={classes.headerPhotos}>
                            <Link to={'/events'}>
                                <Image src={eventImage} className={classes.serviceImg}  xs={6} md={4} roundedCircle />
                            </Link>
                        </Row>
                        <Row className={classes.headerContent}>
                            <article>
                                Find events at your local game store.
                            </article>
                        </Row>
                    </Col>
                    <Col md="4">
                        <Row className={classes.headerContent}>
                            <h2>Local Games</h2>
                        </Row>
                        <Row className={classes.headerPhotos}>
                            <Link to={'/games'}>
                                <Image src={collectionImage} className={classes.serviceImg} alt="Shelves with a variety of board games." roundedCircle />
                            </Link>
                        </Row>
                        <Row className={classes.headerContent}>
                            <article>
                                Find Games in Stock at your local game store.
                            </article>
                        </Row>
                    </Col>
                    <Col md="4">
                        <Row className={classes.headerContent}>
                            <h2>Local Groups</h2>
                        </Row>
                        <Row>
                            <Link to={'/groups'}>
                                <Image src={groupImage} className={classes.serviceImg}  xs={6} md={4} roundedCircle />
                            </Link>
                        </Row>
                        <Row className={classes.headerContent}>
                            <article>
                                Coming Soon
                                {/* Create groups for tracking plays. Track who won, how much the game was enjoyed. Share your library with your group and see what games you have to share. */}
                            </article>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HomePage;