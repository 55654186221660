import { QueryClient } from '@tanstack/react-query';
export const queryClient = new QueryClient(); 
export const environment = process.env.REACT_APP_ENVIRONMENT;
export const apiurl = process.env.REACT_APP_API;
export const gameImages =  `${process.env.REACT_APP_DOMAIN}/external-images/games/`;
export const storeImages = `${process.env.REACT_APP_DOMAIN}/external-images/stores/`;

export const QUERY_KEYS = {
    GAMES: 'games',
    STORES: 'stores',
    EVENTS: 'events',
}