export default class Event {
    constructor(event) {
        this.start = event.start ?? "";
        this.end =  event.end ?? "";
        this.attendees = event.attendees ?? [];
        this.location = event.location ?? "";
        this.locationtype = event.locationtype ?? "";
        this.description = event.description ?? "";
        this.name = event.name ?? "";
        this.games = event.games ?? [];
        this.ownerid = event.ownerid ?? "";
        this.haschildren = event.haschildren ?? false;
        this.parenteventid = event.parenteventid ?? null;
        this.id = event.id ?? "";
    }
}