import { QUERY_KEYS, queryClient } from "../../data/config";
import AddGameModal from "../games/AddGameModal";
import { updateStore } from "../../data/store-api";
import classes from './Managers.module.css';
import { useState } from "react";
import { Table, Button, Form } from 'react-bootstrap';
import RemoveButton from "../shared/ui/buttons/RemoveButton";

const InventoryManager = ({store}) => {
    const [inventoryGameIds, setInventoryGameIds] = useState(store.inventoryids);
    const [showAddGame, setShowAddGame] = useState(false);
    const [updatingInventory, setUpdatingInventory] = useState(false);
    const [inventoryFilter, setInventoryFilter] = useState('');
    const [inventoryError, setInventoryError] = useState('');
    const toggleAddGameModal = () => setShowAddGame(!showAddGame);

    const onGameModalSave = async (games) => {
        toggleAddGameModal();
        setUpdatingInventory(true);
        store.inventoryids = store.inventoryids.concat(games.map(game => game.id));
        store.inventory = store.inventory.concat(games);
        const result = await updateStore(store);
        setUpdatingInventory(false);
        if(result.includes('Error')) {
            setInventoryError(result);
        } else {
            onSuccessfulStoreUpdate(store);
        }
    }

    const onRemoveGameClick = async (gameId) => {
        store.inventory = store.inventory.filter(game => { return game.id !== gameId });
        store.inventoryids = store.inventoryids.filter(id => { return id !== gameId });
        const result = await updateStore(store);
        if(result.includes('Error')) {
            setInventoryError(result);
        } else {
            onSuccessfulStoreUpdate(store);
        }
    }

    const onSuccessfulStoreUpdate = (store) => { 
        setInventoryError('');
        setInventoryGameIds(store.inventoryids);
        queryClient.refetchQueries([QUERY_KEYS.STORES]);
    }

   
    // Function to handle the change in the filter input
    const handleFilterChange = (e) => {
        setInventoryFilter(e.target.value);
    };

    // Filter and sort games based on the filter text and alphabetically by name
    const filteredAndSortedGames = store.inventory
        .filter(game => game.name.toLowerCase().includes(inventoryFilter.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));


    return (
        <>
            { updatingInventory && 
                <section>
                    <h3>Updating Inventory....</h3>
                </section>
            }
            { inventoryError !== '' && 
                <section>
                    <h3>{inventoryError}</h3>
                </section>
            }
            <section>
                <Table className={classes.table} responsive>
                    <thead>
                        <tr>
                            <th className={classes.gameNameCol}>
                            <div>
                                <span>Game</span>
                                <Form.Control type="text" placeholder="Filter by name..." value={inventoryFilter} onChange={handleFilterChange} />
                            </div>
                            </th>
                            <th><Button onClick={toggleAddGameModal}>Add</Button></th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredAndSortedGames.map((game, index) => (
                            <tr key={index}>
                                <td className={classes.gameNameCol}>{game.name}</td>
                                <td><RemoveButton onClick={onRemoveGameClick} data={game.id}>Remove Game</RemoveButton></td>
                            </tr>
                        )) }
                    </tbody>
                </Table>
            </section>
            <AddGameModal show={showAddGame} onClose={toggleAddGameModal} onSave={onGameModalSave} ownedGames={inventoryGameIds}/>
        </>
    )
}

export default InventoryManager;