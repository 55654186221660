import {
  Button,
  Container,
  Tab,
  Tabs,
  TabContainer,
  Row,
  Col,
  Image
} from "react-bootstrap";
import classes from "./Profile.module.css";
import avatar from "../profile/images/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg";
import MyGamesList from "../../components/filterList/MyGamesList";
import { Link } from "react-router-dom";
import EditProfileComponent from "../../components/profile/EditProfileComponent";

const ProfilePage = () => {
  const profile = { }; //This needs to come from a loader
  const {
    email,
    username,
    firstname,
    lastname,
    phoneNumber,
    birthdate,
    why,
    favoriteGame,
    favStores,
    games,
    address,
  } = profile;

  return (
    <Container>
      <br/>
      {/* Profile Pic & username */}
      <Row>
        <Col xs={12} sm={12} md={2}>
          <Image src={avatar} className={classes.profileImage} alt="avatar" /> <span>@{profile.username}</span>{" "}
        </Col> 

        <Col xs={2} sm={2} md={9}></Col>

        <Col xs={6} sm={6} md={1}>
          <div className="view">
            <Link to={"/public_profile"}>
              <Button
                className={classes.view_pfp}
                color="link"
                id="view_pub_pfp"
                type="submit"
                size="sm"
                style={{ height: "50px", width: "100px" }}
              >
                View Public Profile
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <h1>
          {" "}
          {profile.firstname} {lastname}{" "}
        </h1>
      </Row>
      {/* <!-- Bootstrap Tabs--> */}
      <Row>
        <Col>
          <Container>
            <Row>
              <TabContainer justify>
                <Tabs defaultActiveKey="profile">
                  <Tab eventKey="profile" title="Profile" id="profile">
                    <Row className="d-flex align-items-center">
                      <Col xs={10} sm={10} md={11}>
                        <h3>Profile</h3>
                      </Col>
                      <Col xs={2} sm={2} md={1} className="mt-3">
                        <EditProfileComponent />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className={classes.headers}>
                          <h4>My Favorite Game</h4>
                        </div>
                        <div className={classes.bio_body}>{favoriteGame}</div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col>
                        <div className={classes.headers}>
                          <h4>Why</h4>
                        </div>
                        <div className={classes.bio_body}>{why}</div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col>
                        <div className={classes.headers}>
                          <h4>Favorite stores</h4>
                        </div>
                        <div className={classes.bio_body}>{favStores}</div>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col>
                        <div className={classes.headers}>
                          <h4>My games</h4>
                        </div>
                        <div className={classes.bio_body}>{games}</div>
                      </Col>
                    </Row>
                    <br/>
                    <Link to={'/edit-public-profile'}>
                      <Button>Edit Public details</Button>
                    </Link>
                  </Tab>


                  <Tab eventKey="events" title="Events" id="events">
                    <br/>
                    <Row className="d-flex align-items-center">
                      <h3>Events</h3>
                    </Row>
                  </Tab>

                  <Tab eventKey="games" title="Games" id="games">
                    <br/>
                    <Row className="d-flex align-items-center">
                      <h3>My Games</h3>
                    </Row>
                    <Row>
                      <MyGamesList />
                    </Row>
                  </Tab>

                  <Tab eventKey="myaccount" title="My Account" id="myaccount">
                    <br/>
                    <Row className="d-flex align-items-center">
                      <Col className="mt-3">
                        <h3>My Account</h3>
                      </Col>
                    </Row>
                    <div className={classes.account_section_headings}>
                      <h4>Email </h4>
                      <span className={classes.profileInfo}>{email}</span>
                      <br />
                    </div>
                    <div className={classes.account_section_headings}>
                      <h4>Username </h4>
                      <span className={classes.profileInfo}>{username}</span>
                      <br />
                    </div>
                    <div className={classes.account_section_headings}>
                      <h4>Phone Number </h4>
                      <span className={classes.profileInfo}>{phoneNumber}</span>
                      <br></br>
                    </div>
                    <div className={classes.account_section_headings}>
                      <h4> Birthday </h4>
                      <span className={classes.profileInfo}>{birthdate}</span>
                      <br></br>
                    </div>
                    <div className={classes.account_section_headings}>
                      <h4>Address </h4>
                      <span className={classes.profileInfo}>{address}</span>
                      <br></br>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="notifications"
                    title="Notifications"
                    id="notifications"
                  >
                    <br/>
                    <h3>New</h3>
                    This is an example notification
                  </Tab>

                  <Tab eventKey="settings" title="Settings" id="settings">
                    <br/>
                    <h3>Preferences</h3>
                    <br></br>
                    <Button>Night mode</Button>
                    <Button>Blocking</Button>
                    <Button> Your games </Button>
                  </Tab>
                </Tabs>
              </TabContainer>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
export default ProfilePage;
