const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: "numeric",
  minute: "numeric"
};
export const dateTimeDisplayFormat = new Intl.DateTimeFormat('en-US', options);

export const formatDateForDB = (date) => {
  const pad = (num) => (num < 10 ? '0' + num : num);
  return date.getFullYear() + '-' +
         pad(date.getMonth() + 1) + '-' +
         pad(date.getDate()) + 'T' +
         pad(date.getHours()) + ':' +
         pad(date.getMinutes());
}