import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Card, Col } from 'react-bootstrap';
import styling from './IAGCard.module.css';
import IAGModal from '../IAGModal/IAGModal';

const IAGCard = (props) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);

    const cardStyling = `${props.cardStyling ?? ''} ${styling.cardFormat}`;
    const cardWrapperStyling = `${props.cardWrapperStyling ?? ''} ${styling.cardWrapper}`;
    const cardTextStyling = `${props.cardTextStyling ?? ''} ${styling.cardText}`;
    let xsmall= 12, small = 12, medium = 6, large = 6, xlarge = 6, xxlarge = 4;
    if (!props.image || props.image === "") {
        small = 12; medium = 6; large = 3; xlarge = 3; xxlarge = 3;
    }

    // adds image if needed
    const image = (imageUrl) => {
        if (imageUrl && imageUrl !== "") {
           return  <Col xs="6">
                { props.dest ? 
                    <Link to={props.dest}><Card.Img className={styling.cardImage} variant="" src={props.image} /></Link>
                    : <Card.Img className={styling.cardImage} variant="" src={props.image} /> }
            </Col>;
        }
        return '';
    }

    // formats columns if needed
    const colFormater = (imageUrl) => {
        let width = 12;
        let body = '';
        if (!(imageUrl === "") && !(imageUrl == null)) {
            width = 6;
            body = styling.cardBody;
        }
        return (<Col xs={width} className='d-flex flex-column'>
                    <Card.Body className={body}>
                        <Card.Title className={styling.cardTitle}>
                            {props.title}
                        </Card.Title>
                        <Card.Text className={cardTextStyling}>
                            {props.text}
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className={styling.cardFooter}>
                        {setButton(props.dest, props.modal !== null, props.Btext)}
                    </Card.Footer>
            </Col>);
    }

    const footer = () => {
        if (props.modalFooter) {
            return (
                <props.modalFooter data={props.data} />
            );
        }
        return null;
    }

    // creates linked button if needed
    const setButton = (destination, isModal, text) => {
        if (isModal) {
            return <Button onClick={toggleModal} className={styling.cardButton}>{text ?? ''}</Button>;
        }
        else if (destination) {
            return <Link to={destination}><Button className={styling.cardButton}>{text ?? ''}</Button></Link>;
        }
        return '';
    }

    return(
        <Col xs={xsmall} sm={small} md={medium} lg={large} xl={xlarge} xxl={xxlarge} className={cardWrapperStyling} >
            <Card id={props.data.id} className={cardStyling}>
                <Row>
                    { image(props.image)}
                    { colFormater(props.image) }
                </Row>
            </Card>
            { props.modal && (
                <IAGModal title={props.title} show={showModal} onClose={toggleModal} footer={footer()} >
                    <props.modal data={props.data} />
                </IAGModal>
            )}
        </Col>
         
        )
}
export default IAGCard;