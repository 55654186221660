import { Row, Container, Col } from 'react-bootstrap';

const ContactPage = () => {
    return (  
        <Container>
            <Row className="justify-content-md-center my-5">
                <Col md={6}>
                    <h1>Contact Us</h1>
                    <p>If you have any questions or feedback, feel free to reach out to us. We're here to help!</p>
                    <a href="mailto:support@iamgame.community">support@iamgame.community</a>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactPage;