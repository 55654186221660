import IAGCard from '../shared/IAGCard/IAGCard';
import {
    Container,
    Row
} from 'react-bootstrap';
import { useState } from 'react';
import styling from './GameList.module.css';
import { gameImages } from '../../data/config';
import GameModal, {Footer} from './GameModal';
import SearchBar from '../shared/ui/SearchBar';


const GameList = ({games}) => {
    //TODO: get games from database with search filter once we have more games
    const [search, setSearch] = useState('');
    // const [sortBy, setSortBy] = useState('');

    const searchChangeHandler = (event) => { 
        setSearch(event.target.value);
    }
    
    const filteredGames = games.filter((game) => {
        if (search === '' || game.name.toLowerCase().includes(search.toLowerCase())
        || game.description.toLowerCase().includes(search.toLowerCase()))
            return game;
        return '';
    })

    return (
        <div>
            <Container>
            <SearchBar onChange={searchChangeHandler}/>
                <Row>
                {filteredGames.map((item) => (
                    <IAGCard 
                        cardTextStyling={styling.cardText}
                        title={item.name}
                        data={item}
                        text={item.description.replace(/<br\/>/g, ' ')}
                        modal={GameModal}
                        modalFooter={Footer}
                        key={item.id}
                        Btext="Details"
                        image={`${gameImages}${item.image}`}  
                    />
                ))}
                </Row>                
            </Container>
        </div>
    )
}

export default GameList;