import { Container, Row } from 'react-bootstrap';
import IAGCard from '../shared/IAGCard/IAGCard';
import EventModal, { Footer } from './EventModal'
import { useState } from 'react';
import SearchBar from '../shared/ui/SearchBar';
import { dateTimeDisplayFormat } from "../../components/shared/utilities/dateTimeUtils";

const generateCardText = (event) => {
    return (
        <>
            {dateTimeDisplayFormat.format(new Date(event.start))} - {dateTimeDisplayFormat.format(new Date(event.end))}<br/>
            {event.location}<br/>
        </>
    );

}

const EventList = ({events}) => {
    //TODO: Search Fiter for data. Until we have too many stores this can all be done locally
    const [search, setSearch] = useState('');
    const searchChangeHandler = (event) => {
        setSearch(event.target.value);
    }

    let showEvents = events;

    if(search !== '') {
        showEvents = events.filter((event) => {
            if (event.name.toLowerCase().includes(search.toLowerCase())
            || event.location.toLowerCase().includes(search.toLowerCase())
            || event.description.toLowerCase().includes(search.toLowerCase()))
                return event;
            return '';
        })
    }

    return (
        <Container>
            <SearchBar onChange={searchChangeHandler}/>
            <Row>
            {showEvents.map((event) => (
                    <IAGCard
                        title={event.name}
                        text={generateCardText(event)}
                        data={event}
                        key={event.id}
                        modal={EventModal}
                        Btext="View Event"
                        modalFooter={Footer}
                    />            
            ))}
            </Row>
        </Container>
    );
}

export default EventList;