import { Outlet } from 'react-router-dom';

const StoreLayout = () => {
  return (
    <>
        <Outlet />
    </>
  );
}

export default StoreLayout;