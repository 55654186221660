import AuthenticatedAdminLayout from "./AdminLayout";
import StoreAdminPage from "./StoreAdminPage";
import { storeLoaderAdmin } from "../../loaders/storeLoaders";
import { gamesLoader } from "../../loaders/gameLoaders";
import AllGames from "./AllGames";

const adminRoutes = {
    path: 'admin',
    element: <AuthenticatedAdminLayout/>,
    children: [
      // {
      //   index:true, 
      //   element: <AdminPage/> 
      // },
      {
        path: "store/:storeId",
        element: <StoreAdminPage />,
        loader: storeLoaderAdmin
      },
      {
        path: "games",
        element: <AllGames />,
        loader: gamesLoader
      }
    ]
};

export default adminRoutes;