import { Amplify } from 'aws-amplify';

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        region: 'us-east-2',
        userPoolId: 'us-east-2_AcCGTX2Lm',
        userPoolClientId: '3kkov647s658oohi5cd46ep181',
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
        userAttributes: {
          email: {
            required: true,
          },
        },
        allowGuestAccess: true,
        passwordFormat: {
          minLength: 8,
          requireLowercase: true,
          requireUppercase: true,
          requireNumbers: true,
          requireSpecialCharacters: true,
        },
      }
    }
  });
};

/**
 * Configures the Amplify library.
 */
export default configureAmplify;