import EventLayout from "./EventLayout";
import AllEvents from "./AllEvents";
import EventDetailPage from "./EventDetail";
import NewEventPage from "./NewEvent";
import EditEventPage from "./EditEvent";
import { eventsLoader } from "../../loaders/eventLoaders";

const eventRoutes = { //    iamgame.community/events/12345/edit
    path: 'events',
    element: <EventLayout />,
    children: [
        { index: true, element: <AllEvents />, loader: eventsLoader },
        // { path: ':eventId', element: <EventDetailPage /> },
        // { path: 'new', element: <NewEventPage /> },
        // { path: ':eventId/edit', element: <EditEventPage /> }
    ]
};

export default eventRoutes;