import { useLoaderData } from "react-router";
import StoreDetail from "../../components/stores/StoreDetail";
import { TabContainer, Tabs, Tab } from 'react-bootstrap';
import EventsManager from "../../components/admin/EventManager";
import InventoryManager from "../../components/admin/InventoryManager";

const StoreAdminPage = () => {
    const store = useLoaderData();
   

    return (
        <>
            <StoreDetail store={store} />
            <TabContainer>
                <Tabs defaultActiveKey="inventory" >
                    <Tab  eventKey="inventory" title="Inventory">
                        <InventoryManager store={store} />
                    </Tab>
                    <Tab eventKey="events" title="Events">
                        <EventsManager store={store} />
                    </Tab>
                </Tabs>
            </TabContainer>         
        </>
    )
}

export default StoreAdminPage;