import{ useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { environment } from '../data/config';

const ShopifyRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (environment === 'development') {
        window.location.href = 'http://localhost:3001/shopify/index.html';
    } else {
        window.location.href = 'https://www.iamgame.community/shopify/index.html';
    }
    
  }, [navigate]);

  return null;
};

export default ShopifyRedirect;