import { Outlet } from 'react-router-dom';
import Footer from '../components/shared/ui/Footer';
import Navigation from '../components/shared/ui/Navigation';

const BaseLayout = () => {
  return (
    <>
      <header>
        <Navigation />
      </header>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default BaseLayout;